
import { computed, defineComponent } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import CardScroll from '@/components/CardScroll.vue'
import NewsCard from '@/components/NewsCard.vue'
import { getNewsItem, getLatestNews, getNewsItemMobile } from '@/kentico-api/news.api'
import useSwrv from 'swrv/dist/use-swrv'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'
import { useRoute } from 'vue-router'

export default defineComponent({

  name: 'Show',
  components: { PageHeader, NewsCard, GenericError, CardScroll },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const route = useRoute()

    const newsItemSource = (slug:string) => {
      if (route.meta?.mobile) {
        return getNewsItemMobile(slug)
      }
      return getNewsItem(slug)
    }

    const { data: newsResponse, error: newsItemError } = useSwrv(
      () => props.slug,
      newsItemSource,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )

    const { data: latestResponse, error: latestNewsError } = useSwrv(
      `/latest-news?except=${props.slug}`,
      () => getLatestNews({ except: props.slug }),
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )

    const newsItem = computed(() => newsResponse.value?.data)
    const latestNews = computed(() => latestResponse.value?.data)

    useHead({
      title: computed(() => newsItem.value?.metadataPageTitle || 'News'),
      meta: [
        {
          name: 'description',
          content: computed(() => newsItem.value?.metadataMetaDescription || ''),
          key: 'description'
        },
        {
          name: 'keywords',
          content: computed(() => newsItem.value?.metadataMetaKeywords || ''),
          key: 'keywords'
        },
        {
          name: 'og:description',
          content: computed(() => newsItem.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => newsItem.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}service-news/${props.slug}`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => newsItem.value && newsItem.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(newsItem.value.openGraphOgImage[0].url,
              { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => newsItem.value?.openGraphOgLocale || ''),
          key: 'ogLocale'
        }
      ]
    })

    const isMobile = computed(() =>
      (route.meta?.mobileAppView ? route.meta.mobileAppView : false) as boolean
    )
    return {
      isMobile,
      newsItem,
      newsItemError,
      latestNews,
      latestNewsError
    }
  }
})
